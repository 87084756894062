import React from 'react';
import styled from 'styled-components';
import {
    LoginTitle,
    submit_button,
    Button
} from '../../util/constant';
import ReactMarkdown from 'react-markdown';
import propTypes from 'prop-types';

const Content = styled.div`
    color:grey;
`;

const Container = styled.div`
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
`;

const MessageBox = ({ title, content, handler }) =>
    <Container>
        <LoginTitle paddingBottom={'10px'}>{title}</LoginTitle>
        <Content>
            <ReactMarkdown source={content} />
        </Content>
        <Button
            {...submit_button}
            onClick={handler}
        >
            OK
        </Button>
    </Container>;

export default MessageBox;

MessageBox.propTypes = {
    title: propTypes.string,
    content: propTypes.string,
    handler: propTypes.func
};

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./Montserrat-Light.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./Montserrat-LightItalic.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./Montserrat-Medium.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./Montserrat-MediumItalic.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./Montserrat-Black.ttf"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./Montserrat-BlackItalic.ttf"));

// Module
exports.push([module.id, "@font-face {\n    font-family: 'montserrat_light';\n    src: url(" + ___CSS_LOADER_URL___0___ + ") format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'montserrat_light_italic';\n    src: url(" + ___CSS_LOADER_URL___1___ + ") format('truetype');\n    font-weight: 400;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'montserrat_medium';\n    src: url(" + ___CSS_LOADER_URL___2___ + ") format('truetype');\n    font-weight: 500;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'montserrat_medium_italic';\n    src: url(" + ___CSS_LOADER_URL___3___ + ") format('truetype');\n    font-weight: 500;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'montserrat_black';\n    src: url(" + ___CSS_LOADER_URL___4___ + ") format('truetype');\n    font-weight: 900;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'montserrat_black_italic';\n    src: url(" + ___CSS_LOADER_URL___5___ + ") format('truetype');\n    font-weight: 900;\n    font-style: italic;\n}\n", ""]);


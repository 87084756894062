import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../util/auth';
import { ROUTE_LOGIN } from '../util/constant';
import PropTypes from 'prop-types';

export default function PrivateRoute ({
    component: Component,
    withProps = {},
    ...rest
})   {
    PrivateRoute.propTypes = {
        component: PropTypes.object,
        withProps: PropTypes.object
    };
    return (
        <Route
            {...rest}
            render={props => {
                const combinedProps = { ...props, ...withProps };
                return (
                    <React.Fragment>
                        {isLoggedIn() ? (
                            <Component {...combinedProps} />

                        ) : (
                            <Redirect
                                to={{
                                    pathname: ROUTE_LOGIN
                                }}
                            />
                        )}
                    </React.Fragment>
                );
            }}
        />
    );
};

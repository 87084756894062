import React from 'react';
import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogBox = ({
    toggle_open,
    on_close_handler,
    title,
    content,
    left_button,
    right_button
}) =>
    <Dialog
        open={toggle_open}
        onClose={on_close_handler}
    >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {content}
            </DialogContentText>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                paddingTop: '10px'
            }}>
                {left_button}
                {right_button}
            </div>
        </DialogContent>
    </Dialog>;

export default DialogBox;

DialogBox.propTypes = {
    toggle_open: propTypes.bool,
    on_close_handler: propTypes.func,
    title: propTypes.string,
    content: propTypes.object,
    left_button: propTypes.object,
    right_button: propTypes.object
};

import { observable, action, computed } from 'mobx';
import { Utils } from '@migenius/realityserver-client';
import { Camera } from '@migenius/realityserver-extras';
import { get_current_package_id as get_package_id } from './shared/util/constant';

class Store {
    @observable current_user = null;
    @observable status = 'loading';
    @observable service = null;
    @observable scene_filename = 'scenes/empty.mi';
    @observable scene_name = 'tetrapak_scene';
    @observable app_scope_name = 'tetrapak_global';
    @observable session_scope_name = `scope_${Utils.create_random_string(8)}`;
    @observable render_loop_name = `render_loop_${Utils.create_random_string(8)}`
    @observable scene;
    @observable stream;
    @observable last_mouse = {
        x: 0,
        y: 0
    };
    @observable settings = []
    // for options in the filter component
    @observable current_package = {
        family: {},
        opening: {},
        shape: {},
        volume: {},
        hotspots: [],
        package_info: {},
        design: []
    };
    // for the currently displayed package
    @observable current_package_render_object = {
        family: {},
        opening: {},
        shape: {},
        volume: {},
        hotspots: [],
        package_info: {}
    };
    @observable current_settings_id = {
        family_id: -1,
        volume_id: -1,
        shape_id: -1,
        opening_id: -1
    };
    @observable current_settings_list = {
        family_list: [],
        volume_list: [],
        shape_list: [],
        opening_list: []
    };
    @observable selected_cap_color = null;
    @observable package_render_data = {};
    @observable rs_camera = new Camera();
    @observable blob_name = null;
    @observable warning = null;
    @observable enable_threejs = false;
    @observable exhibition_mode = false;
    @observable first_render = true;
    @observable is_using_preconfigured_design = false;
    @observable fov = null;
    @observable is_iframe = false;
    @observable url_config = {};
    @observable url_inital = true;
    @observable hide_header = false;

    pause_camera_updates = false;

    camera_under_ground = false;

    @computed get get_current_package_import_path() {
        const { family, volume, shape, opening } = this.current_package;
        return `scenes/packages/${family.short_name}/${volume.short_name}/` +
            `${shape.short_name}/${opening.short_name}/main.mi`;
    };

    @computed get get_current_package_id() {
        return get_package_id(this.current_package);
    };

    @computed get current_package_render_data() {
        return this.package_render_data[this.get_current_package_id];
    }

    @action set_enable_threejs = enable_threejs => {
        this.enable_threejs = enable_threejs;
    };

    @action set_is_using_preconfigured_design = value => {
        this.is_using_preconfigured_design = value;
    };

    @action set_exhibition_mode = exhibition_mode => {
        this.exhibition_mode = exhibition_mode;
    };

    @computed get get_exhibition_mode() {
        return this.exhibition_mode;
    };

    @action set_fov = fov => {
        this.fov = fov;
    };

    @action set_first_render = first_render => {
        this.first_render = first_render;
    };

    @action set_blob_name = blob_name => {
        this.blob_name = blob_name;
    };

    @action set_rs_camera = rs_camera => {
        this.rs_camera = rs_camera;
    };

    @action set_current_package_render_object = current_package_render_object => {
        this.current_package_render_object = current_package_render_object;
    };

    @computed get get_current_package_render_object_id() {
        if (this.current_package_render_object) {
            const { family, volume, shape, opening } = this.current_package_render_object;
            return `${family.short_name}-${volume.short_name}-${shape.short_name}-${opening.short_name}`;
        }
    };

    @computed get get_current_package_render_object_id_without_opening() {
        if (this.current_package_render_object) {
            const { family, volume, shape } = this.current_package_render_object;
            return `${family.short_name}_${volume.short_name}_${shape.short_name}`;
        }
    };

    @action add_package_render_data = (package_id, data) => {
        this.package_render_data[package_id] = data;
    };

    @action set_is_iframe = (bool) => {
        this.is_iframe = bool;
    };

    @action set_url_config = (obj) => {
        this.url_config = obj;
    };
    @action set_url_inital = (bool) => {
        this.url_inital = bool;
    };
    @action set_hide_header = (bool) => {
        this.hide_header = bool;
    };
    @action set_current_settings_id = current_settings_id => {
        this.current_settings_id = current_settings_id;
    };

    @action set_current_settings_list = current_settings_list => {
        this.current_settings_list = current_settings_list;
    };

    @action set_current_user = user => {
        this.current_user = user;
    };

    @computed get get_current_user_full_name() {
        return this.current_user ? `${this.current_user.first_name} ${this.current_user.last_name}` : null;
    };

    @action set_settings = settings => {
        this.settings = settings;
    };

    @action set_current_package = current_package => {
        this.current_package = current_package;
        this.blob_name = null;
        this.selected_cap_color = null;
    };

    @action set_status = status => {
        this.status = status;
    };

    @action set_service = service => {
        this.service = service;
    };

    @action set_scene = scene => {
        this.scene = scene;
    };

    @action set_last_mouse = next_mouse => {
        if (next_mouse.x) this.last_mouse.x = next_mouse.x;
        if (next_mouse.y) this.last_mouse.y = next_mouse.y;
    };

    @action set_stream = stream => {
        this.stream = stream;
    }

    set_pause_camera_updates = value => {
        this.pause_camera_updates = !!value;
    }

    get_pause_camera_updates = () => {
        return this.pause_camera_updates;
    }

    set_camera_under_ground = value => {
        this.camera_under_ground = !!value;
    }

    get_camera_under_ground = () => {
        return this.camera_under_ground;
    }

    @action set_selected_cap_color = cap_color => {
        this.selected_cap_color = cap_color;
    }

    @action add_warning = warning => {
        if (typeof warning !== 'string') {
            console.error(`Incorrect warning type ${typeof warning}`);
            return;
        }
        this.warning = warning;
    }

    @action remove_warning = () => {
        if (!this.warning) {
            return;
        }
        this.warning = null;
    }

    @action reset_state() {
        session_scope_name = `scope_${Utils.create_random_string(8)}`;
        render_loop_name = `render_loop_${Utils.create_random_string(8)}`;
        scene = undefined;
        stream = undefined;
        last_mouse = {
            x: 0,
            y: 0
        };
        settings = [];
        // for options in the filter component
        current_package = {
            family: {},
            opening: {},
            shape: {},
            volume: {},
            hotspots: [],
            package_info: {},
            design: []
        };
        // for the currently displayed package
        current_package_render_object = {
            family: {},
            opening: {},
            shape: {},
            volume: {},
            hotspots: [],
            package_info: {}
        };
        current_settings_id = {
            family_id: -1,
            volume_id: -1,
            shape_id: -1,
            opening_id: -1
        };
        current_settings_list = {
            family_list: [],
            volume_list: [],
            shape_list: [],
            opening_list: []
        };
        selected_cap_color = null;
        package_render_data = {};
        rs_camera = new Camera();
        blob_name = null;
        warning = null;
        enable_threejs = false;
        exhibition_mode = false;
        first_render = true;
        is_using_preconfigured_design = false;
    }

}
export default Store;

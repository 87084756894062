import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SingleColumnContent = styled.div`
    grid-area: 2 / 1 / 3 / 3;
    padding: 0 150px 50px 150px;
`;

const SingleColumnTemplate = ({ children }) =>
    <SingleColumnContent>{children}</SingleColumnContent>;

export default SingleColumnTemplate;

SingleColumnTemplate.propTypes = {
    children: PropTypes.any
};

import { ACCESS_TOKEN_KEY } from './constant';
import axios from 'axios';
import { API_URL, send_request } from './constant';

export function isLoggedIn() {
    return Boolean(localStorage.getItem(ACCESS_TOKEN_KEY));
}

export const logout = async () => {
    await send_request('/uploaded_image/clean');


    localStorage.removeItem(ACCESS_TOKEN_KEY);

};

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export async function login(email, password ) {
    try {
        const response = await axios.post(
            `${API_URL}/users/signin`,
            { email, password } );
        if (response.status === 200 && response.data) {
            const { token } =   response.data;
            localStorage.setItem(ACCESS_TOKEN_KEY, token);
        }
        return response;
    } catch (error) {
        throw error;
    }
}

import React from 'react';
import Logo from '../Logo';
import styled from 'styled-components';

const logo_style = {
    position: 'absolute',
    top: '20%',
    left: '37px'
};

const LoginContainer = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 200px auto;
    width:50%;
    min-width: 1024px;
    margin: 0 auto;
`;

const LoginHeader = styled.div`
    grid-area: 1 / 1 / 2 / 3;
    position: relative;
`;

const RootTemplate = (Comp) => (props) => (
    <LoginContainer>
        <LoginHeader>
            <Logo style={logo_style} />
        </LoginHeader>
        <Comp {...props} />
    </LoginContainer>
);

export default RootTemplate;

import React from 'react';
import styled from 'styled-components';
import {
    CORE_COLOR,
    InputField,
    LabelSection,
    Button,
    LoginTitle
} from '../../util/constant';
import propTypes from 'prop-types';

const Text = styled.div`
    color: grey;
    font-size:0.9em;
    padding-bottom: 10px;
  `;

const ButtonSection = styled.div`
    display:flex;
    flex-direction:row;
  `;

const ErrorMessage = styled.p`
    color: red;
`;

const Status = styled.span`
    color: ${CORE_COLOR.corporate_blue};
`;

const SimpleForm = ({
    title,
    form_desc,
    input_fields,
    button_lists,
    state,
    on_change,
    warning,
    checkbox_section,
    status,
    error,
    message,
    messageComp
}) =>
    <div>
        {title && <LoginTitle paddingBottom={'10px'}>{title}</LoginTitle>}
        {message && <div>{message}</div>}
        {messageComp && messageComp}
        {warning && <ErrorMessage>{warning}</ErrorMessage>}
        {status && <Status>{status}</Status>}
        {!error && form_desc && <Text>{form_desc}</Text>}
        {!error && input_fields && input_fields.map(({ name, label, type, on_key_down = null }, index) =>
            <div key={index}>
                <LabelSection style={{ color: 'grey' }}>{label}:</LabelSection>
                <InputField
                    name={name}
                    value={state[name] || ''}
                    onChange={on_change}
                    type={type || 'text'}
                    onKeyDown={on_key_down}
                />
            </div>
        )}
        {!error && checkbox_section}
        {!error && button_lists && <ButtonSection>
            {button_lists.map(({ display_name, handler, style }, index) =>
                <Button
                    key={index}
                    style={{ margin: 0, marginRight: '10px' }}
                    {...style}
                    onClick={handler}
                >
                    {display_name}
                </Button>)}
        </ButtonSection>}
    </div>;

export default SimpleForm;

SimpleForm.propTypes = {
    title: propTypes.string,
    form_desc: propTypes.string,
    input_fields: propTypes.array,
    button_lists: propTypes.array,
    state: propTypes.object,
    on_change: propTypes.func,
    warning: propTypes.string,
    checkbox_section: propTypes.object,
    status: propTypes.string,
    error: propTypes.bool,
    message: propTypes.string,
    messageComp: propTypes.object
};

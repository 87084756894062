import React from 'react';
import {
    Button,
    ROUTE_LOGIN,
    LoginTitle,
    submit_button
} from '../util/constant';
import content from '../../assets/about.md';
import { withRouter } from 'react-router-dom';
import RootTemplate from './templates/RootTemplate';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { DOWNLOAD_PATH } from 'tetrapak-config';
import SingleColumnTemplate from './templates/SingleColumnTemplate';


const About = (props) => {
    function handle_click_back() {
        props.history.push(ROUTE_LOGIN);
    }

    return <SingleColumnTemplate>
        <LoginTitle>Package Visualizer</LoginTitle>
        <ReactMarkdown source={content} />
        <a href={`${DOWNLOAD_PATH.INTRODUCTION_FILE}`}>
            Learn more about Package Visualizer
        </a>
        <Button
            style={{ margin: '50px 0 0 0' }}
            {...submit_button}
            onClick={handle_click_back}
        >
            Back
        </Button>
    </SingleColumnTemplate>;
};
export default withRouter(RootTemplate(About));

About.propTypes = {
    history: PropTypes.object,
};

import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import axios from 'axios';
import { getAccessToken } from './auth';
import { API_URL_CONFIG, ENVIRONMENT } from 'tetrapak-config';
import styled from 'styled-components';
import { Command, Command_error } from '@migenius/realityserver-client';
import { isEmpty } from 'lodash';
import { toJS } from 'mobx';

export { SECONDARY_GREEN as SECONDARY_COLOR } from './secondary_color';

export const ACCESS_TOKEN_KEY = 'accessTokenKey';

export const ROUTE_LOGIN = '/';

export const ROUTE_ADMIN_HOME = '/admin';

export const ROUTE_ADMIN_ANALYTICS_LOG = '/analytics/log';

export const ROUTE_ADMIN_ANALYTICS_PACKAGE = '/analytics/package';

export const ROUTE_CONTENT_HOME = '/content';

export const ROUTE_FORGOT_PASSWORD = '/forgot_password';

export const ROUTE_RESET_PASSWORD = '/reset_password/:token';

export const ROUTE_REQUEST_ACCESS = '/request_access';

export const ROUTE_ABOUT = '/about';

export const ROUTE_SUPPORT = '/support';

export const ROUTE_TERMS_CONDITIONS = '/terms_conditions';

export const ROUTE_SAML_CALLBACK = '/saml/:token';

export const ROUTE_SAML_ERROR = '/saml_error/:message';

export const ROUTE_IFRAME = '/iframe';

export const API_URL = ENVIRONMENT === 'production'
    ? API_URL_CONFIG.PROD
    : API_URL_CONFIG.DEV;

export const ROUTE_SAML_LOGIN = API_URL + '/users/saml/signin';

axios.defaults.withCredentials = true;

function get_headers(content_type) {
    const headers =
    {
        'content-type': content_type
    };
    const token = getAccessToken();
    if (token) {
        headers.authorization = token;
    }
    return headers;
}

export const send_request = async (url, is_embedded = false) => {
    return await axios.get(
        `${API_URL}${is_embedded ? '/embedded' : ''}${url}`,
        {
            headers: get_headers('application/json')
        }
    );
};

export const send_patach_request = async (url, data) => {
    return await axios.patch(
        `${API_URL}${url}`,
        data,
        {
            headers: get_headers('application/json')
        }
    );
};

export const send_post_request = async (url, data) => {
    return await axios.post(
        `${API_URL}${url}`,
        data,
        {
            headers: get_headers('application/json')
        }
    );
};

export const send_upload_request = async (url, data) => {
    return await axios.post(
        `${API_URL}${url}`,
        data,
        {
            headers: get_headers('multipart/form-data')
        }
    );
};

export const RADIAL_CONVERSION = Math.PI / 600;

export const LINEAR_CONVERSION = Math.PI / 600;

export const PAN_SENSITIVITY = 0.07;

export const transform_srgb_to_linear = val => {
    const SRGB_A = 0.055;
    const SRGB_G = 2.4;
    if (val <= 0.04045) {
        return val / 12.92;
    } else {
        return Math.pow((val + SRGB_A) / (1 + SRGB_A), SRGB_G);
    }
};

export const size_of = (bytes) => {
    if (bytes === 0) {
        return '0.00 B';
    }
    let e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
};

const save_package = async (
    type,
    fk_package_id = null,
    fk_cap_color_id = null,
    design_filename = null,
    is_iframe = false
) => {
    await send_post_request(`${is_iframe ? '/embedded' : ''}/analytics/package/`,
        {
            type,
            ...(Boolean(fk_package_id) && {
                fk_package_id
            }),
            ...(Boolean(fk_cap_color_id) && {
                fk_cap_color_id
            }),
            ...(Boolean(design_filename) && {
                design_filename
            })
        });
};

const analytics_functions = {
    package: save_package,
    cap_color: save_package,
    material: save_package,
    login: save_package
};

export const record_analytics = (type, data) => {
    if (analytics_functions[type]) {
        const {
            current_package: { package_info: { id: package_id } },
            selected_cap_color,
            blob_name,
            is_iframe
        } = data;
        analytics_functions[type](
            type,
            package_id,
            selected_cap_color && selected_cap_color.id,
            blob_name,
            is_iframe);
    } else {
        throw new Error('analytics type not known');
    }
};

export const formatDate = date => {
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    const dateLocal = new Date(date.getTime() - offsetMs);
    return dateLocal.toISOString().slice(0, 19).replace(/-/g, '/').replace('T', ' ');
};

export const CORE_COLOR = {
    corporate_blue: '#023f88',
    corporate_blue_disabled: '#c7d8ed',
    sky_blue: '#94c6f0',
    medium_red: '#ff0000',
    white: '#ffffff',
    disabled_gray: '#dbdbdb'
};

export const download_file = (data, name) => {
    const csvData = 'data:text/csv;charset=utf-8,' + data;
    const encodedUri = encodeURI(csvData);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', name);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "analytic_data.csv"
};

export const ROOT_STYLES = {
    root: {
        fontFamily: 'montserrat_light, times'
    },
};

export const TableCell = styled.p`
    overflow: auto;
    -ms-overflow-style: none;  // IE 10+
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none
    }
`;

export const download_file_info = [{
    url: '/analytics/logs/csv',
    filename: 'all_logs.csv',
    key: 'logs'
}, {
    url: '/analytics/package_popularity/csv',
    filename: 'popularity.csv',
    key: 'popularity'
}];

export const change_texture = async (blob_name, store, is_preconfigured = false) => {
    const {
        current_package: { package_info: { diffuse_path = '' } = {} } = {},
        package_render_data,
        get_current_package_id,
        stream,
        set_blob_name
    } = store;
    const { original_image, has_custom_texture, prefix } = package_render_data[get_current_package_id];
    const queue = stream.queue_commands();

    try {
        if (!has_custom_texture) {
            queue.queue(new Command('tp_prepare_texture', {
                texture_name: `${prefix}${diffuse_path}`
            }), true);
        }
        queue.queue(new Command('tp_set_texture', {
            texture_name: `${prefix}${diffuse_path}`,
            filename: blob_name,
            environment: ENVIRONMENT === 'production' ? 'prod' : 'dev'
        }), true);

        if (!has_custom_texture) {
            const [original_image_string, downloaded] = await queue.execute();
            if (!Boolean(original_image)) {
                package_render_data[get_current_package_id].original_image = original_image_string;
            }
            if (downloaded instanceof Command_error) {

                store.add_warning('Design File Error1!');
            }
        } else {
            const [downloaded] = await queue.execute();
            if (downloaded instanceof Command_error) {
                store.add_warning('Design File Error2!');
            }
        }
    } catch (error) {
        store.add_warning(error.message);
    }
    package_render_data[get_current_package_id].has_custom_texture = true;
    set_blob_name(blob_name);
};

/**
 This method adds commands to an existing queue to render the cap color.
 You must send this queue yourself.
 It also takes the liberty of changing the selected cap color in mobx
*/
export const add_render_cap_color_to_queue = async (
    current_package_render_object,
    prefix,
    cap_color,
    editable_materials,
    queue,
    set_selected_cap_color,
    set_current_package_render_object
) => {
    const {
        diffuse_red,
        diffuse_green,
        diffuse_blue,
        display_string,
        identity_string
    } = cap_color;
    if (!editable_materials) {
        return;
    }
    // when we set a color, find the material being set and update it's color values
    if (current_package_render_object.opening.materials) {
        const new_package_render_object = { ...current_package_render_object };
        new_package_render_object.opening.materials =
            current_package_render_object.opening.materials.map(mat => {
                for (let i = 0; i < editable_materials.length; i++) {
                    if (editable_materials[i] === mat.id) {
                        queue.queue(
                            new Command('mdl_set_argument', {
                                element_name: `${prefix}${mat.name}`,
                                argument_name: 'diffuse',
                                value: {
                                    r: transform_srgb_to_linear(diffuse_red),
                                    g: transform_srgb_to_linear(diffuse_green),
                                    b: transform_srgb_to_linear(diffuse_blue)
                                }
                            })
                        );
                        return {
                            ...mat,
                            diffuse_blue,
                            diffuse_green,
                            diffuse_red,
                            display_string,
                            identity_string
                        };
                    }
                }
                return mat;
            });
        set_current_package_render_object(new_package_render_object);
    }
    set_selected_cap_color(cap_color);
};

/* Takes a package and returns an object which contains a list of materials
    to edit, and the parent material of that list
*/
export const get_editable_materials = (current_package_render_object) => {
    const { editable_materials } = current_package_render_object;
    let material_group, material_group_parent;
    if (!isEmpty(editable_materials)) {
        // only render the first material
        material_group_parent = Object.keys(editable_materials)[0];
        material_group = editable_materials[material_group_parent];
    }
    return {
        material_group_parent,
        material_group
    };
};

export const get_current_package_id = current_package => {
    if (isEmpty(current_package)) {
        return '';
    }
    const { family, volume, shape, opening } = current_package;
    return `${family.short_name}-${volume.short_name}-${shape.short_name}-${opening.short_name}`;
};

export const IMAGE_DIMENSIONS = {
    width: 1280,
    height: 720,
    min_width: 850
};

export const capitalize_first_letter_text = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const use_stores = () => React.useContext(MobXProviderContext);

const ButtonStyled = styled.div`
    width: ${props => props.width ? props.width : '106px'};
    height: 40px;
    background-color:${props => props.disabled ? CORE_COLOR.disabled_gray : props.bg_color};
    color:${props => props.font_color};
    border-radius:5px;
    text-align:center;
    line-height:40px;
    margin-left: 10px;
    font-size:0.9em;
    font-weight:500;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    :hover{
        background-color:${props => props.disabled ? undefined : props.hover_bg_color};
    }
`;

export const Button = props => {
    return (
        <ButtonStyled
            {...props}
            onClick={props.disabled ? undefined : props.onClick}
        />
    )
};

export const InputField = styled.input`
   height:36px;
   line-height:38opx;
   border-radius:5px;
   border : 1px solid black;
   outline:none;
   padding-left:8px;
   width:calc(100% - 8px);
   margin-bottom:15px;
`;

export const LabelSection = styled.div`
   padding: 5px  0px;
   text-align:left;
   font-size: 0.9em;
`;

export const LoginTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    color:grey;
    padding-bottom:${props => props.paddingBottom ? props.paddingBottom : '26px'};
`;

export const submit_button = {
    bg_color: CORE_COLOR.corporate_blue,
    font_color: 'white',
    hover_bg_color: CORE_COLOR.sky_blue
};

export const cancel_button = {
    bg_color: 'white',
    font_color: 'black',
    hover_bg_color: 'lightgrey'
};

export const build_initial_state = (fileds) => fileds.reduce((obj, item) => (obj[item.name] = '', obj), {});

export const validate_password = (password) => /[A-Z]/.test(password)
    + /[a-z]/.test(password)
    + /[0-9]/.test(password)
    + /[-~!@#$%^&*()+=`\[\]\\;',./{}|:"<>?]/.test(password);

export const LoadingContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100vw;
    height:100vh;
    background-color:white;
    position:fixed;
    top:0;
    left:0;
    z-index: 100;
`;

export const compute_current_package_to_url = ({
    url_inital,
    current_package,
    selected_cap_color = null,
    hide_header,
    select_design_id = -1
}) => {
    if (!url_inital) {
        const { family, opening, shape, volume } = current_package
        let new_url = `?family=${family.short_name}&volume=${volume.short_name}&shape=${shape.short_name}&opening=${opening.short_name}${hide_header ? `&hide_header` : ''}${selected_cap_color ? `&color=${encodeURIComponent(selected_cap_color.identity_string)}` : ''}${select_design_id && select_design_id !== -1 ? `&predefined_design_id=${select_design_id}` : ''}`
        history.pushState({}, null, new_url);
    }
}
import React from 'react';
import {
    Button,
    ROUTE_LOGIN,
    LoginTitle,
    submit_button
} from '../util/constant';
import content from '../../assets/support.md';
import { withRouter } from 'react-router-dom';
import RootTemplate from './templates/RootTemplate';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import SingleColumnTemplate from './templates/SingleColumnTemplate';


const Support = (props) => {
    function handle_click_back() {
        props.history.push(ROUTE_LOGIN);
    }

    return <SingleColumnTemplate>
        <LoginTitle>Support</LoginTitle>
        <ReactMarkdown source={content} />
        <Button
            style={{ margin: '50px 0 0 0' }}
            {...submit_button}
            onClick={handle_click_back}
        >
            Back
        </Button>
    </SingleColumnTemplate>;
};
export default withRouter(RootTemplate(Support));

Support.propTypes = {
    history: PropTypes.object,
};

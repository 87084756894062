import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LeftSection = styled.div`
    margin: 0 auto;
    width:300px;
`;

const RightSection = styled.div`
    font-size: 0.9em;
    padding-left: 90px;
    border-left: 1px solid lightgray;
`;

const DoubleColumnTemplate = ({ left, right }) =>
    <Fragment>
        <LeftSection>{left}</LeftSection>
        <RightSection>{right}</RightSection>
    </Fragment>;

export default DoubleColumnTemplate;

DoubleColumnTemplate.propTypes = {
    left: PropTypes.object,
    right: PropTypes.object
};

import React from 'react';

const OverlayElement = (Comp) => (props) => (
    <div style={{
        pointerEvents: 'auto',
        zIndex: -1,
        cursor: 'default',
        userSelect: 'none'
    }}
    >
        <Comp {...props} />
    </div>
);

export default OverlayElement;

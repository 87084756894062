import { observable, action } from 'mobx';
class Store {
    @observable users = [];
    @observable roles = [];
    @observable admin_user_table_next_page = -1;
    @observable admin_user_table_current_page = -1;
    @observable admin_user_table_page_is_jump = false;

    @action set_users = users => {
        this.users = users;
    };

     @action set_admin_user_table_next_page = page => {
         this.admin_user_table_next_page = page;
     };

    @action set_admin_user_table_current_page = page => {
        this.admin_user_table_current_page = page;
    };

    @action set_roles = roles => {
        this.roles = roles;
    };

    @action set_admin_user_table_page_is_jump = bool => {
        this.admin_user_table_page_is_jump = bool;
    };
}
export default Store;


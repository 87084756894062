import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    LoginTitle,
    send_post_request,
    submit_button,
    cancel_button,
    ROUTE_LOGIN,
    build_initial_state
} from '../util/constant';
import { withRouter } from 'react-router';
import RootTemplate from './templates/RootTemplate';
import propTypes from 'prop-types';
import DoubleColumnTemplate from './templates/DoubleColumnTemplate';
import ReactMarkdown from 'react-markdown';
import { forgot_password_schema } from '../util/validation_schema';
import Loading from './Loading';
import content from '../../assets/forgot_password.md';
import SimpleForm from './templates/SimpleForm';
import SingleColumnTemplate from '../components/templates/SingleColumnTemplate';
import reset_password_email_content from '../../assets/reset_password_email.md';
import MessageBox from './templates/MessageBox';

const INPUT_FIELDS = [ {
    label: 'Email',
    name: 'email'
} ];

const INITIAL = () => build_initial_state(INPUT_FIELDS);

const ForgotPassword =
    (props) => {
        const [ state, set_state ] = useState(INITIAL);
        const [ is_loading, set_is_loading ] = useState(false);
        const [ warning, set_warning ] = useState(undefined);
        const [ status, set_status ] = useState(undefined);
        const [ is_complete, set_is_complete ] = useState(false);
        const Buttons = [
            {
                display_name: 'Send',
                handler: handle_submit,
                style: submit_button
            },
            {
                display_name: 'Cancel',
                handler: handle_cancel,
                style: cancel_button
            }
        ];

        function on_change({ target: { name, value } }) {
            if (status) {
                set_status(undefined);
            }
            set_state(prev_state => ({ ...prev_state, [name]: value }));
        }

        useEffect(() => {
            if (is_loading) {
                const { email } = state;
                set_is_loading(true);
                forgot_password_schema.validate({ email })
                    .then(async () => {
                        const response = await send_post_request('/users/forgot_password', { email });
                        if (response.data.error) {
                            set_is_loading(false);
                            set_warning(response.data.error);
                        }
                        if (response.data.message) {
                            set_status(response.data.message);
                            set_is_loading(false);
                            set_warning(undefined);
                            set_is_complete(true);
                        }
                    })
                    .catch(({ message }) => {
                        set_is_loading(false);
                        set_warning(message);
                    });
            }
        }, [ is_loading ]);

        function handle_submit() {
            set_is_loading(true);
        }

        function handle_cancel() {
            props.history.push(ROUTE_LOGIN);
        }

        if (is_loading) {
            return <Loading />;
        }

        return (
            is_complete ?
                <SingleColumnTemplate>
                    <MessageBox
                        title='Reset password'
                        content={reset_password_email_content}
                        handler={handle_cancel}
                    />
                </SingleColumnTemplate>
                :
                <DoubleColumnTemplate
                    left={
                        <SimpleForm
                            title={'Reset Password'}
                            form_desc={"Please enter your username and we'll send the instructions of how to reset your password!"}
                            input_fields={INPUT_FIELDS}
                            button_lists={Buttons}
                            state={state}
                            on_change={on_change}
                            status={status}
                            warning={warning}
                        />
                    }
                    right={
                        < div >
                            <LoginTitle paddingBottom={'10px'}>Forgot your username?</LoginTitle>
                            <ReactMarkdown style={{ color: 'grey' }} source={content} />
                        </div >
                    }
                />
        );
    };

export default RootTemplate(withRouter(observer(ForgotPassword)));

ForgotPassword.propTypes = {
    history: propTypes.object
};

import React, { Component, lazy, Suspense } from 'react';
import { observer, inject } from 'mobx-react';
import { Route } from 'react-router-dom';
import Login from '../../shared/components/Login';
import ForgotPassword from '../../shared/components/ForgotPassword';
import ResetPassword from '../../shared/components/ResetPassword';
import RequestAccess from '../../shared/components/RequestAccess';
import About from '../../shared/components/About';
import Support from '../../shared/components/Support';
import Terms from '../../shared/components/Terms';
import {
    ROUTE_LOGIN,
    ROUTE_CONTENT_HOME,
    send_request,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_RESET_PASSWORD,
    ROUTE_REQUEST_ACCESS,
    ROUTE_ABOUT,
    ROUTE_SAML_CALLBACK,
    ROUTE_SAML_ERROR,
    ROUTE_TERMS_CONDITIONS,
    ROUTE_SUPPORT,
    ROUTE_IFRAME,
    LoadingContainer
} from '../../shared/util/constant';
import { isLoggedIn } from '../../shared/util/auth';
import PrivateRoute from '../../shared/components/PrivateRoute';
import styled from 'styled-components';
import './App.css';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Loading from '../../shared/components/Loading';
import { withRouter } from 'react-router';
import '../../shared/util/shared.css';
import { IFRAME_WHITELIST } from 'tetrapak-config';

const ContentComponent = lazy(() => import('./content/View'));

const MainContainer = styled.div`
    height: 100%;
    width: 100%;
    min-height: 90vh;
    position: relative;
    user-select: none;
`;

const private_routes = [
    {
        path: ROUTE_CONTENT_HOME,
        component: ContentComponent,
        withProps: {}
    }
];

const public_routes = [
    {
        path: ROUTE_FORGOT_PASSWORD,
        component: ForgotPassword,
        exact: true
    },
    {
        path: ROUTE_RESET_PASSWORD,
        component: ResetPassword,
        exact: true
    },
    {
        path: ROUTE_REQUEST_ACCESS,
        component: RequestAccess,
        exact: true
    },
    {
        path: ROUTE_ABOUT,
        component: About,
        exact: true
    },
    {
        path: ROUTE_TERMS_CONDITIONS,
        component: Terms,
        exact: true
    },
    {
        path: ROUTE_SUPPORT,
        component: Support,
        exact: true
    },
    {
        path: ROUTE_IFRAME,
        component: ContentComponent,
        exact: true
    }
];

const login_routes = [
    {
        path: ROUTE_SAML_CALLBACK
    },
    {
        path: ROUTE_SAML_ERROR
    },
    {
        path: ROUTE_LOGIN
    }
];

@inject('store')
@observer
class App extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            has_loaded_url_config: false,
            valid_iframe: true
        };
        const {
            current_user,
            set_current_user,
            add_warning
        } = props.store;
        if (isEmpty(current_user) && isLoggedIn()) {
            try {
                send_request('/users/current')
                    .then(({ data }) => {
                        console.log(data);
                        set_current_user(data.user);
                    })
                    .catch(e => add_warning(e.message));

            } catch (error) {
                console.log(error);
            };
        }
    }

    componentDidMount() {
        const {
            set_url_config,
            set_is_iframe,
            set_hide_header
        } = this.props.store;
        console.log('is_embedded', this.props.root_is_embedded);
        set_is_iframe(this.props.root_is_embedded);
        if (this.props.root_is_embedded && document.referrer) {
            const referrer = new URL(document.referrer);
            this.setState({
                valid_iframe: !!IFRAME_WHITELIST.find(e => e === referrer.hostname || referrer.hostname.substr(-1 * (e.length + 1) === '.' + e))
            })
        }
        if (this.props.location.search) {
            const url_config = Array.from(new URLSearchParams(this.props.location.search).entries())
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
            set_url_config(url_config)
            set_hide_header(this.props.root_is_embedded && url_config.hide_header === "");
        }
    }
    render() {

        const { remove_warning, is_iframe } = this.props.store;
        if (!this.state.valid_iframe) return <h1>INVALID IFRAME ACCESS</h1>
        return (
            <MainContainer onClick={remove_warning}>
                <Suspense fallback={<LoadingContainer><Loading /></LoadingContainer>}>
                    {login_routes.map(({ path }, index) =>
                        <Route
                            key={index}
                            path={path}
                            exact
                            render={() => isLoggedIn() || is_iframe ? <ContentComponent /> : <Login />}
                        />)}
                    {public_routes.map(({ path, exact, component }, index) =>
                        <Route
                            key={index}
                            path={path}
                            exact={exact}
                            component={component}
                        />)}
                    {private_routes.map(({ path, component, withProps }, index) => (
                        <PrivateRoute
                            key={index}
                            path={path}
                            component={component}
                            withProps={withProps}
                        />
                    ))}
                </Suspense>
            </MainContainer>
        );
    }
}

App.propTypes = {
    store: PropTypes.object,
    root_is_embedded: PropTypes.bool
};

export default withRouter(App);

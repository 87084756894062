import React, { useState, useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    ROUTE_TERMS_CONDITIONS,
    ROUTE_SAML_LOGIN,
    LoginTitle,
    send_post_request,
    submit_button,
    ROUTE_LOGIN,
    cancel_button,
    build_initial_state
} from '../util/constant';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import RootTemplate from './templates/RootTemplate';
import PropTypes from 'prop-types';
import DoubleColumnTemplate from './templates/DoubleColumnTemplate';
import ReactMarkdown from 'react-markdown';
import content from '../../assets/request_access.md';
import { SAML_EMAIL_SUFFIX } from 'tetrapak-config';
import Checkbox from '@material-ui/core/Checkbox';
import { request_access_schema } from '../util/validation_schema';
import Loading from './Loading';
import SimpleForm from './templates/SimpleForm';
import SingleColumnTemplate from '../components/templates/SingleColumnTemplate';
import MessageBox from './templates/MessageBox';
import DialogBox from './templates/DialogBox';

const TermsText = styled.p`
    margin: 0;
    padding: 0;
    font-size:0.9em;
    padding-left:12px;
 `;

const INPUT_FIELDS = [
    {
        label: 'Email',
        name: 'email'
    },
    {
        label: 'First name',
        name: 'first_name'
    },
    {
        label: 'Last name',
        name: 'last_name'
    },
    {
        label: 'Company',
        name: 'company'
    },
    {
        label: 'Role in company',
        name: 'role_in_company'
    },
    {
        label: 'Tetra Pak contact email',
        name: 'contact_person_email'
    },
    {
        label: 'Reason for access',
        name: 'reason_for_access'
    }
];

const INITIAL = build_initial_state(INPUT_FIELDS);

const RequestAccess =
    (props) => {
        const [ state, set_state ] = useState(INITIAL);
        const [ warning, set_warning ] = useState(undefined);
        const [ status, set_status ] = useState(undefined);
        const [ is_loading, set_is_loading ] = useState(false);
        const [ accept_terms, set_accept_terms ] = useState(false);
        const [ show_saml_message, set_show_saml_message ] = useState(false);
        const [ completion_feedback, set_completion_feedback ] = useState(undefined);

        const Buttons = [
            {
                display_name: 'Submit',
                handler: handle_submit,
                style: submit_button
            },
            {
                display_name: 'Cancel',
                handler: handle_cancel,
                style: cancel_button
            }
        ];

        useEffect(() => {
            if (props.location.state){
                const { accept_terms, ...rest } = props.location.state;
                set_state(rest);
                set_accept_terms(accept_terms);
            }
        }, []);

        useEffect(() => {
            if (is_loading) {
                const {
                    email,
                    first_name,
                    last_name,
                    company,
                    role_in_company,
                    contact_person_email,
                    reason_for_access,
                } = state;
                validate()
                    .then(() => (
                        send_post_request('/users/request_access', {
                            email,
                            first_name,
                            last_name,
                            company,
                            role_in_company,
                            contact_person_email,
                            reason_for_access
                        })
                            .then((response) => {
                                if (response.data.error) {
                                    set_warning(response.data.error);
                                }
                                if (response.data.message) {
                                    set_state(INITIAL);
                                    set_status(response.data.message);
                                    set_completion_feedback(response.data.message);
                                }
                                set_is_loading(false);
                                set_show_saml_message(false);
                            })
                            .catch(error => {
                                if (error.response) {
                                    set_warning(error.message);
                                }
                                set_is_loading(false);
                                set_show_saml_message(false);
                            })
                    ));
            }
        }, [ is_loading ]);

        function validate() {
            const {
                email,
                first_name,
                last_name,
                company,
                role_in_company,
                contact_person_email,
                reason_for_access
            } = state;

            return request_access_schema.validate({
                email,
                first_name,
                last_name,
                company,
                role_in_company,
                contact_person_email,
                reason_for_access,
                accept_terms
            })
                .then(() => set_warning(undefined))
                .catch(e => {
                    set_warning(e.message);
                    throw e;
                });
        }

        function on_change({ target: { name, value } }) {
            if (status) {
                set_status(undefined);
            }
            set_state(prev_state => ({ ...prev_state, [name]: value }));
        }

        function toggle_accept_terms() {
            set_accept_terms(!accept_terms);
        }

        function handle_cancel() {
            props.history.push(ROUTE_LOGIN);
        }

        function handle_submit() {
            validate().then(() => {
                const { email } = state;
                if (!show_saml_message && SAML_EMAIL_SUFFIX.filter(each => email.includes(each)).length > 0) {
                    set_show_saml_message(true);
                } else {
                    set_is_loading(true);
                }
            });
        }

        function handle_saml_message() {
            set_show_saml_message(!show_saml_message);
        }

        function handle_saml_click() {
            window.location = ROUTE_SAML_LOGIN;
        }

        if (is_loading) return <Loading />;

        return (
            completion_feedback ?
                <SingleColumnTemplate>
                    <MessageBox
                        title='Request Sent'
                        content={completion_feedback}
                        handler={handle_cancel}
                    />
                </SingleColumnTemplate>
                :
                <Fragment>
                    <DoubleColumnTemplate
                        left={
                            <SimpleForm
                                input_fields={INPUT_FIELDS}
                                button_lists={Buttons}
                                state={state}
                                on_change={on_change}
                                warning={warning}
                                checkbox_section={
                                    <div style={{ display: 'flex', paddingBottom: '20px' }}>
                                        <Checkbox
                                            style={{ color: 'grey', padding: 0 }}
                                            checked={accept_terms}
                                            onChange={toggle_accept_terms}
                                        />
                                        <div>
                                            <TermsText>I have read and accepted the</TermsText>
                                            <TermsText>
                                                <Link to={{
                                                    pathname: ROUTE_TERMS_CONDITIONS,
                                                    state: { ...state, accept_terms }
                                                }} >
                                            Terms of use and Disclaimers.
                                                </Link>
                                            </TermsText>
                                        </div>
                                    </div>
                                }
                            />
                        }
                        right={
                            <div style={{ width: '300px' }}>
                                <LoginTitle>Request access form</LoginTitle>
                                <ReactMarkdown source={content} />
                            </div>
                        }
                    />
                    <DialogBox
                        toggle_open={show_saml_message}
                        on_close_handler={handle_saml_message}
                        title="Tetra Pak Employees"
                        content={<span>
                            Tetra Pak employees do not need to request access to the system.
                            You can login directly via the <a style={{ textDecoration: 'none' }}
                                href={ROUTE_SAML_LOGIN}>Employee Signin system</a>.
                        </span>}
                        left_button={
                            <Button
                                style={{ margin: 0, width: '160px' }}
                                {...submit_button}
                                onClick={handle_saml_click}
                            >
                                Employee Sign In
                            </Button>
                        }
                        right_button={
                            <Button
                                style={{ margin: 0, width: '160px' }}
                                {...submit_button}
                                onClick={handle_submit}
                            >
                                Request Access
                            </Button>
                        }
                    />
                </Fragment>
        );
    };

export default RootTemplate(withRouter(observer(RequestAccess)));

RequestAccess.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    LoginTitle,
    send_post_request,
    submit_button,
    cancel_button,
    ROUTE_LOGIN,
    build_initial_state,
    validate_password
} from '../util/constant';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import RootTemplate from './templates/RootTemplate';
import DoubleColumnTemplate from './templates/DoubleColumnTemplate';
import ReactMarkdown from 'react-markdown';
import { reset_password_schema } from '../util/validation_schema';
import Loading from './Loading';
import _password_policy_content from '../../assets/password_policy.md';
import reset_password_success_content from '../../assets/reset_password_success.md';
import SimpleForm from './templates/SimpleForm';
import SingleColumnTemplate from '../components/templates/SingleColumnTemplate';
import PropTypes from 'prop-types';
import MessageBox from './templates/MessageBox';

const INPUT_FIELDS = [
    {
        label: 'New Password',
        name: 'password',
        type: 'password'
    },
    {
        label: 'Re-type Password',
        name: 'confirmed_password',
        type: 'password'
    }
];

const INITIAL = () => build_initial_state(INPUT_FIELDS);

const Content = styled.div`
    color:grey;
`;

const ResetPassword =
    (props) => {
        const [ state, set_state ] = useState(INITIAL);
        const [ warning, set_warning ] = useState(undefined);
        const [ is_loading, set_is_loading ] = useState(false);
        const [ is_complete, set_is_complete ] = useState(false);
        const [ error, set_error ] = useState(false);

        const Buttons = [
            {
                display_name: 'Reset',
                handler: handle_submit,
                style: submit_button
            },
            {
                display_name: 'Cancel',
                handler: handle_cancel,
                style: cancel_button
            }
        ];

        useEffect(() => {
            if (is_loading) {
                const { password, confirmed_password } = state;
                reset_password_schema.validate({ password, confirmed_password })
                    .then(async () => {
                        try {
                            const count = validate_password(password);
                            if (count >= 3 && password.length >= 8) {
                                const response = await send_post_request('/users/update_password',
                                    {
                                        token: props.match.params.token,
                                        password: state.password
                                    }
                                );
                                if (response.status === 200 && response.data.message) {
                                    set_is_complete(true);
                                }
                            } else {
                                set_warning('Minimum 8 characters and must contain at least one character from 3 of the following 4 groups: lowercase letters (a-z), uppercase letters (A-Z), numbers (0-9) and punctuation characters (e.g. !#&%)');
                            }

                        } catch (error) {
                            console.log(error);

                            set_warning(error.response.data.message);
                        }
                    })
                    .catch(({ message }) => {
                        set_warning(message);
                    });
                set_is_loading(false);
            }
        }, [ is_loading ]);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const response = await send_post_request('/users/reset_password',
                        { token: props.match.params.token }
                    );
                    if (response.status === 200 && response.data.error) {
                        set_error(true);
                        set_warning(response.data.error);
                    }

                } catch (error) {
                    set_warning(error.response.data.message);
                }
            };
            fetchData();
        }, []);

        function on_change({ target: { name, value } }) {
            set_state(prev_state => ({ ...prev_state, [name]: value }));
        }

        function handle_submit() {
            if (is_complete) {
                set_is_complete(false);
            }
            set_is_loading(true);
        }

        function handle_cancel() {
            props.history.push(ROUTE_LOGIN);
        }

        if (is_loading) {
            return <Loading />;
        }

        return (
            is_complete ?
                <SingleColumnTemplate>
                    <MessageBox
                        title='Reset password'
                        content={reset_password_success_content}
                        handler={handle_cancel}
                    />
                </SingleColumnTemplate>
                :
                <DoubleColumnTemplate
                    left={
                        <SimpleForm
                            title={'Reset Password'}
                            form_desc={'Please choose a new password!'}
                            input_fields={INPUT_FIELDS}
                            button_lists={Buttons}
                            state={state}
                            on_change={on_change}
                            warning={warning}
                            error={error}
                        />
                    }
                    right={
                        <Content>
                            <LoginTitle paddingBottom={'10px'}>Password Policy</LoginTitle>
                            <ReactMarkdown source={_password_policy_content} />
                        </Content>
                    }
                />
        );
    };

export default RootTemplate(withRouter(observer(ResetPassword)));

ResetPassword.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};


import * as yup from 'yup';

export const login_schema = yup.object().shape({
    email: yup.string('Your Email is not valid')
        .email('Your Email is not valid')
        .required('Please provide your email'),
    password: yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
});

export const forgot_password_schema = yup.object().shape({
    email: yup.string('Your Email is not valid')
        .email('Your Email is not valid')
        .required('Please provide your email')
});

export const reset_password_schema = yup.object().shape({
    password: yup.string('Your password is not valid.')
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.'),
    confirmed_password: yup.string('Your confirm password is not valid.')
        .oneOf([ yup.ref('password'), null ],
            "Passwords don't match").required('Confirm password is required'),
});

export const request_access_schema = yup.object().shape({
    reason_for_access: yup.string('Reason for access is not valid')
        .required('Reason for access is required'),
    contact_person_email: yup.string('Contact person email is not valid')
        .matches(/@migenius.com$|@tetrapak.com$/, 'Contact person email is not valid')
        .email('Contact person email is not valid')
        .required('Contact person email is required'),
    role_in_company: yup.string('Role in company is not valid')
        .required('Role in company is required'),
    company: yup.string('Company is not valid')
        .required('Company is required'),
    last_name: yup.string('First Name is not valid')
        .required('Last Name is required'),
    first_name: yup.string('First Name is not valid')
        .required('First Name is required'),
    email: yup.string('Your Email is not valid')
        .email('Your Email is not valid')
        .required('Please provide your email'),
    accept_terms: yup.mixed().oneOf([ true ], 'You must accept the terms and conditions')
});

export const queue_job_schema = yup.object({
    email: yup.string().email().required()
});

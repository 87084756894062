import React from 'react';
import {
    Button,
    LoginTitle,
    submit_button,
    ROUTE_REQUEST_ACCESS
} from '../util/constant';
import content from '../../assets/terms_conditions.md';
import { withRouter } from 'react-router-dom';
import RootTemplate from './templates/RootTemplate';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import SingleColumnTemplate from './templates/SingleColumnTemplate';
import styled from 'styled-components';

const ScrollWrapper = styled.div`
    height: 50vh;
    overflow-y: auto;
 `;

const Terms = (props) => {
    function handle_click_back() {
        const { location: { state }, history: { push, goBack } } = props;
        state ? push(ROUTE_REQUEST_ACCESS, state) : goBack();
    }

    return <SingleColumnTemplate>
        <LoginTitle>Terms and Conditions for use</LoginTitle>
        <ScrollWrapper>
            <ReactMarkdown source={content} />
        </ScrollWrapper>
        <Button
            style={{ margin: '50px 0 0 0' }}
            {...submit_button}
            onClick={handle_click_back}
        >
            Back
        </Button>
    </SingleColumnTemplate>;
};
export default withRouter(RootTemplate(Terms));

Terms.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

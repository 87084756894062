import React, { useState, useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import {
    use_stores,
    record_analytics,
    send_request,
    ROUTE_ADMIN_HOME,
    ROUTE_LOGIN,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_REQUEST_ACCESS,
    ROUTE_TERMS_CONDITIONS,
    ROUTE_ABOUT,
    ROUTE_SUPPORT,
    ACCESS_TOKEN_KEY,
    ROUTE_SAML_LOGIN,
    submit_button,
    Button
} from '../util/constant';
import styled from 'styled-components';
import { login_schema } from '../util/validation_schema';
import { login } from '../util/auth';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import RootTemplate from './templates/RootTemplate';
import PropTypes from 'prop-types';
import DoubleColumnTemplate from './templates/DoubleColumnTemplate';
import SimpleForm from './templates/SimpleForm';
import DialogBox from './templates/DialogBox';
import { SAML_EMAIL_SUFFIX } from 'tetrapak-config';

const TextContainer = styled.div`
    padding-bottom:10px;
    p {
        padding : 0;
        margin: 0;
    }
`;

const BoldText = styled.span`
    font-weight: bold ;
`;

const Messager = styled.p`
    padding : 0;
    margin: 0;
`;

const MessagerContainer = styled.div`
    padding-bottom: 15px;
    font-size: 0.9em;
`;

const Login =
    (props) => {
        const [state, set_state] = useState({ email: '', password: '' });
        const [show_sso_message_box, set_show_sso_message_box] = useState(false);
        const { store, store: { add_warning, remove_warning, warning, url_config } } = use_stores();
        const { history: { push, location }, is_admin, match: { params } } = props;
        const Buttons = [{
            display_name: 'Login',
            handler: handle_submit,
            style: submit_button
        }];

        useEffect(() => {
            if (location.pathname.includes('/saml_error/')) {
                const error_message = SAML_ERROR_MESSAGE[params.message] || 'SAML ERROR';
                add_warning(error_message);
            }
            if (!isEmpty(params.token)) {
                localStorage.setItem(ACCESS_TOKEN_KEY, params.token);
                record_analytics('login', store);
                try {
                    send_request('/users/current').then(({ data }) => {
                        store.set_current_user(data.user);
                        push('/');
                    })
                        .catch(e => add_warning(e.message));
                } catch (error) {
                    console.error(error);
                };
            }
        }, []);

        function on_change({ target: { name, value } }) {
            set_state(prev_state => ({ ...prev_state, [name]: value }));
        }

        function handle_key_down(e) {
            if (e.keyCode === 13) {
                handle_submit();
            }
        }

        async function handle_submit() {
            const { email, password } = state;
            event.preventDefault();
            await login_schema.validate({ email, password })
                .then(async () => {
                    if (SAML_EMAIL_SUFFIX.filter(each => email.includes(each)).length > 0 && !show_sso_message_box) {
                        set_show_sso_message_box(true);
                    } else {
                        set_show_sso_message_box(false);
                        try {
                            const { status, data, error = null } = await login(email, password);
                            if (error) {
                                throw error;
                            }
                            if (status === 200 && data) {
                                store.set_current_user(data.user);
                                record_analytics('login', store);
                                push(is_admin ? ROUTE_ADMIN_HOME : ROUTE_LOGIN);
                                remove_warning();
                            }
                        } catch (error) {
                            add_warning(error.response.data);
                        }
                    }


                })
                .catch((error) => {
                    add_warning(error.message);
                });
        }

        const INPUT_FIELDS = [
            {
                name: 'email',
                label: 'Email',
                type: 'text'
            },
            {
                name: 'password',
                label: 'Password',
                type: 'password',
                on_key_down: handle_key_down
            }
        ];

        function handle_saml_click() {
            window.location = ROUTE_SAML_LOGIN;
        }

        function handle_saml_redirect() {
            if (Object.keys(url_config).length > 2) {
                console.log('sessionStorage');
                sessionStorage.setItem("url_config", JSON.stringify(url_config));
            }
        }

        function handle_close_message() {
            set_show_sso_message_box(!show_sso_message_box);
        }

        return (
            <Fragment>
                <DoubleColumnTemplate
                    left={
                        <SimpleForm
                            title={'Login to Package Visualizer'}
                            input_fields={INPUT_FIELDS}
                            button_lists={Buttons}
                            state={state}
                            on_change={on_change}
                            warning={warning}
                            messageComp={
                                <MessagerContainer>
                                    <Messager>If you are a <BoldText>Tetra Pak employee,</BoldText></Messager>
                                    <a onClick={handle_saml_redirect} style={{ textDecoration: 'none' }} href={ROUTE_SAML_LOGIN}>Sign in here</a>
                                </MessagerContainer>
                            }
                        />
                    }
                    right={
                        <Fragment>
                            <TextContainer>
                                <p><BoldText>External users</BoldText> please request access or</p>
                                <p>reset your password here </p>
                            </TextContainer>
                            <TextContainer>
                                <Link style={{ textDecoration: 'none' }} to={ROUTE_REQUEST_ACCESS}>Request access ></Link>
                            </TextContainer>
                            <TextContainer>
                                <Link style={{ textDecoration: 'none' }} to={ROUTE_FORGOT_PASSWORD}>Reset password ></Link>
                            </TextContainer>
                            <TextContainer style={{ paddingTop: '20px' }}>
                                <p>Other useful links:</p>
                            </TextContainer>
                            <TextContainer>
                                <Link style={{ textDecoration: 'none' }} to={ROUTE_ABOUT}>What is Package Visualizer? ></Link>
                            </TextContainer>
                            <TextContainer>
                                <Link style={{ textDecoration: 'none' }} to={ROUTE_SUPPORT}>Need support? ></Link>
                            </TextContainer>
                            <TextContainer style={{ paddingTop: '20px' }}>
                                <p>By logging on you agree to the site </p>
                                <Link to={ROUTE_TERMS_CONDITIONS}>Terms of use and Disclaimers.</Link>
                            </TextContainer>
                            <TextContainer style={{ paddingTop: '20px' }}>
                                <p>Copyright &#9400; 1999-2019 Tetra Pak International</p>
                                <p>S.A All Rights Reserved.</p>
                            </TextContainer>
                        </Fragment>
                    }
                />
                <DialogBox
                    toggle_open={show_sso_message_box}
                    on_close_handler={handle_close_message}
                    title="Tetra Pak Employees"
                    content={<span>
                        Tetra Pak employees do not need to login to the system locally.
                You can login directly via the <a style={{ textDecoration: 'none' }}
                            href={ROUTE_SAML_LOGIN}>Employee Signin system</a>.
                    </span>}
                    left_button={
                        <Button
                            style={{ margin: 0, width: '160px' }}
                            {...submit_button}
                            onClick={handle_saml_click}
                        >
                            Employee Sign In
                        </Button>
                    }
                    right_button={
                        <Button
                            style={{ margin: 0, width: '160px' }}
                            {...submit_button}
                            onClick={handle_submit}
                        >
                            Local Login
                        </Button>
                    }
                />
            </Fragment>
        );
    };

export default RootTemplate(withRouter(observer(Login)));

Login.propTypes = {
    history: PropTypes.object,
    is_admin: PropTypes.object,
    match: PropTypes.object
};

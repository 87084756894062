import React from 'react';
import ReactDOM from 'react-dom';
import AdminRootStore from './store_admin';
import RootStore from './store';
import App from './app/components/App';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

const store = new RootStore();
const store_admin = new AdminRootStore();

ReactDOM.render(
    <Provider store_admin={store_admin} store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { CORE_COLOR } from '../../shared/util/constant';
const LoginContainer = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }
    render() {
        return (
            <div>
                <LoginContainer>
                    <div>
                        <ClipLoader
                            sizeUnit={'px'}
                            size={100}
                            color={CORE_COLOR.sky_blue}
                            loading={this.state.loading}
                        />
                    </div>
                </LoginContainer>
            </div>
        );
    }
}

export default Loading;
